<template>
  <div class="patient-steps-tab">
    <v-row>
      <v-col>
        <div class="border-card">
          <div class="title mb-4">Informace o krocích</div>
          <v-row>
            <v-col md="7">Dnes ujito kroků:</v-col>
            <v-col class="text-right">
              {{ getTodaySteps() }}
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col md="7">Poslední záznam kroků:</v-col>
            <v-col class="text-right">{{
              stepsStatistics?.stepsLastTrackedAt
                ? getCzechDateWithoutTime(stepsStatistics.stepsLastTrackedAt)
                : "Nebyly zaznamenány žádné kroky"
            }}</v-col>
          </v-row>
        </div>

        <div class="border-card mt-4">
          <div class="d-flex align-center" v-for="(l, i) in legends" :key="i">
            <v-icon :color="l.color" class="mt-2 mr-2" size="30"
              >mdi-run</v-icon
            >
            <div v-html="l.text"></div>
          </div>
        </div>
      </v-col>
      <v-col md="7">
        <div class="border-card" flat>
          <div class="d-flex justify-space-between mb-4 align-center">
            <div>
              {{ calendarTitle }}
            </div>
            <div class="d-flex">
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="loading-container">
            <v-progress-linear
              v-if="calendarLoading"
              indeterminate
              color="accent"
            ></v-progress-linear>
          </div>
          <v-calendar
            type="month"
            v-model="calendarValue"
            ref="calendar"
            @change="updateTitle"
          >
            <template v-slot:day-label="{ day, date }">
              <div>
                <div class="pa-4">
                  <div>
                    {{ day }}
                  </div>
                  <v-icon :color="getStepsColor(date)" class="mt-2" size="30"
                    >mdi-run</v-icon
                  >
                  <div>
                    <small>
                      {{ getDaySteps(date).toLocaleString("cs-CZ") }}
                      <div>kroků</div>
                    </small>
                  </div>
                </div>
              </div>
            </template>
          </v-calendar>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useApiCall from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";

export default {
  setup() {
    const { getData } = useApiCall();
    const {
      getCzechDate,
      getDateToSend,
      getEndOfMonth,
      getStartOfMonth,
      getCzechDateWithoutTime,
    } = useFormatDate();
    return {
      getData,
      getDateToSend,
      getEndOfMonth,
      getStartOfMonth,
      getCzechDate,
      getCzechDateWithoutTime,
    };
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      calendarValue: new Date(),
      thresholds: {
        below60: [2999, 6999, 7000],
        between60And79: [1999, 5999, 6000],
        above80: [999, 3999, 4000],
      },
      calendarTitle: "",
      calendarLoading: false,
      steps: [],
      dayStepsAmount: null,
      stepsStatistics: null,
      lastStepsRecordAt: null,
    };
  },
  async mounted() {
    await this.fetchSteps();
    await this.fetchStepsStatistics();
  },
  computed: {
    patientAge() {
      return new Date().getFullYear() - this.patient.birthYear;
    },

    legends() {
      if (this.patientAge < 60) {
        return this.getLegend(this.thresholds.below60);
      } else if (this.patientAge >= 60 && this.patientAge <= 79) {
        return this.getLegend(this.thresholds.between60And79);
      } else if (this.patientAge >= 80) {
        return this.getLegend(this.thresholds.above80);
      } else {
        return null; // Default case if none of the conditions match
      }
    },
  },

  methods: {
    async fetchStepsStatistics() {
      const patientId = Number(this.$route.params.id);
      const params = {
        patientId,
      };

      const { statistics } = await this.getData("steps/statistics", {
        params,
      });
      this.stepsStatistics = statistics;
    },
    async fetchSteps() {
      this.steps = [];
      this.calendarLoading = true;
      const patientId = Number(this.$route.params.id);

      const params = {
        patientId: patientId,
        from: this.getDateToSend(this.getStartOfMonth(this.calendarValue)),
        end: this.getDateToSend(this.getEndOfMonth(this.calendarValue)),
      };

      const { steps } = await this.getData("steps", {
        params,
      });

      this.steps = steps;
      this.calendarLoading = false;
    },

    getLegend(thresholds) {
      return [
        {
          text: `Více než ${thresholds[2].toLocaleString("cs-CZ")} kroků`,
          color: "green",
        },

        {
          text: `${(thresholds[0] + 1).toLocaleString("cs-CZ")} až ${(
            thresholds[1] + 1
          ).toLocaleString("cs-CZ")} kroků`,
          color: "orange",
        },
        {
          text: `Méně než ${(thresholds[0] + 1).toLocaleString("cs-CZ")} kroků`,
          color: "red",
        },
      ];
    },

    getStepsColor(date) {
      const steps = this.getDaySteps(date);
      const age = this.patientAge;

      const getStepColor = (steps, thresholds) => {
        if (steps <= thresholds[0]) {
          return "red";
        } else if (steps <= thresholds[1]) {
          return "orange";
        } else if (steps >= thresholds[2]) {
          return "green";
        } else {
          return "gray";
        }
      };

      if (age < 60) {
        return getStepColor(steps, this.thresholds.below60);
      } else if (age >= 60 && age <= 79) {
        return getStepColor(steps, this.thresholds.between60And79);
      } else if (age >= 80) {
        return getStepColor(steps, this.thresholds.above80);
      } else {
        return "gray"; // Default case if none of the conditions match
      }
    },

    getDaySteps(date) {
      const daySteps = this.steps.find((s) => {
        const formattedDate = this.getDateToSend(s.occurredOnLocal);
        if (formattedDate === date) {
          return s;
        }
      });

      const amount = daySteps?.amount || 0;
      return amount;
    },

    getTodaySteps() {
      const today = this.getDateToSend(new Date());

      const todaySteps = this.steps.find((i) => {
        const formattedDate = this.getDateToSend(i.occurredAt);
        if (formattedDate === today) {
          return i;
        }
      });

      return todaySteps ? todaySteps.amount : 0;
    },

    async next() {
      this.calendarValue = this.getStartOfMonth(
        new Date(
          this.calendarValue.getFullYear(),
          this.calendarValue.getMonth() + 1
        )
      );

      await this.fetchSteps();
    },
    async prev() {
      this.calendarValue = this.getStartOfMonth(
        new Date(
          this.calendarValue.getFullYear(),
          this.calendarValue.getMonth() - 1
        )
      );

      await this.fetchSteps();
    },
    updateTitle() {
      this.calendarTitle = this.$refs.calendar.title || "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
