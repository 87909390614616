import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import useApicall from "../use/apiCall";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: null,
    userFullName: null,
    batchNumbers: null,
    medicines: null,
    formMessages: {
      type: "error",
      texts: [],
    },
    loginAgainText: null,
    products: [],
    checkoutItems: 0,
    orderSummary: [],
    loginUserType: 'patient',
    patientActivityStatus: null,
  },
  mutations: {
    setDefaultState(state) {
      state.userInfo = null;
      state.userFullName = null;
      state.batchNumbers = null;
      state.medicines = null;
      state.loginAgainText = null;
      state.products = [];
      state.checkoutItems = 0;
      state.orderSummary = [];
      state.patientActivityStatus = null;
    },
    setBatchNumbers(state, value) {
      state.batchNumbers = value;
    },
    setPatientActivityStatus(state, value) {
      state.patientActivityStatus = value;
    },
    addBatchNumber(state, value) {
      state.batchNumbers.push(value);
    },
    setFormMessages(state, formMessages) {
      state.formMessages = formMessages;
    },
    setUserFullName(state, value) {
      state.userFullName = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setAuth(state, auth) {
      state.userInfo.auth = auth;
    },
    setLoginAgainText(state, text) {
      state.loginAgainText = text;
    },

    setProducts(state, products) {
      state.products = products;
    },
    setIncreaseProduct(state, index) {
      const itemCount = state.products[index].count;
      if (itemCount === 5) {
        state.formMessages = {
          type: "error",
          texts: ["Překročili jste limit objedání položek"],
        };
        return;
      }
      state.products[index].count++;
      state.formMessages = {
        type: "success",
        texts: ["Položka byla přidána do objednávky"],
      };
    },
    setDecreaseProduct(state, index) {
      const itemCount = state.products[index].count;
      if (itemCount === 0) {
        return;
      }
      state.products[index].count--;
    },
    setOrderSummary(state, orderSummary) {
      state.orderSummary = orderSummary;
    },
    setMedicines(state, medicines) {
      state.medicines = medicines;
    },
    setLoginUserType(state, type) {
      state.loginUserType = type;
    }
  },
  getters: {
    getUserRole(state) {
      return state.userInfo?.user?.role;
    },
    getUser(state) {
      return state.userInfo?.user;
    },
    getUserFullName(state) {
      return state.userFullName;
    },
    getBatchNumbers(state) {
      return state.batchNumbers?.map((el) => el.batchNumber.toUpperCase());
    },
    getToken(state) {
      return state?.userInfo?.auth?.accessToken;
    },
    getTokenExpiration(state) {
      return state?.userInfo?.auth?.accessTokenExpiresAt;
    },
    getPatientActivityStatus(state) {
      return state.patientActivityStatus;
    },
    getIsAuthorized(state) {
      if (state.userInfo || state.batchNumbers) {
        return true;
      } else false;
    },
    getLoginAgainText(state) {
      return state.loginAgainText;
    },
    getErrors(state) {
      return state.formMessages.texts;
    },
    getProducts(state) {
      return state.products;
    },
    getUserBatchNumber(state) {
      return state?.userInfo?.user?.batchNumbers.map(
        (batchNumber) => batchNumber.medicine.id
      );
    },
    getMedicinesId(state) {
      const medicineId = state.medicines.map((medicine) => {
        return medicine.id;
      });
      return medicineId;
    },
    getCheckoutItems(state) {
      const countArray = state.products.map((el) => el.count);
      const sumOfCounts = countArray.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      return sumOfCounts;
    },
    getOrderSummary(state) {
      return state.orderSummary;
    },
    getLoginUserType(state) {
      return state.loginUserType;
    }
  },
  actions: {
    async fetchProducts(context) {
      const { getData } = useApicall();

      if (context.getters.getUserRole === "admin") {
        return;
      }

      const params = new URLSearchParams();
      const medicineIds = context.getters.getMedicinesId;
      medicineIds.forEach((id) => {
        params.append("medicinesIds", id);
      });


      let request = {
        params: params,
      };

      const { products: products } = await getData("products", request);
      products.forEach((element) => {
        element.count = 0;
      });
      context.commit("setProducts", products);
    },
  },
  modules: {},

  plugins: [new VuexPersistence().plugin],
});

export default store;
