import store from '../store';
import axios from 'axios';
import checkTokenExpirationToRedirect from '../use/checkTokenExpirationToRedirect';

export default function () {
  async function getRawData(url, queryParams, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? { headers: { Authorization: `Bearer ${store.getters.getToken}` } }
        : { headers: { 'Batch-Numbers': store.getters.getBatchNumbers } };
      store.commit('setFormMessages', null);
      const response = await axios.get(url, {
        ...headers,
        ...queryParams,
      });

      return response;
    } catch (error) {
      const erorArray = error.response.data.errors;
      const errorTexts = erorArray.map((error) => error.message);
      const errodObject = {};
      errodObject.texts = errorTexts;
      errodObject.type = 'error';
      store.commit('setFormMessages', errodObject);
    }
  }
  async function getData(url, queryParams, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store.getters.getToken;
      const headers = token
        ? { headers: { Authorization: `Bearer ${store.getters.getToken}` } }
        : { headers: { 'Batch-Numbers': store.getters.getBatchNumbers } };

      store.commit('setFormMessages', null);
      const { data: response } = await axios.get(url, {
        ...headers,
        ...queryParams,
      });

      return response;
    } catch (error) {
      const erorArray = error?.response?.data?.errors ?? [];
      const errorTexts = erorArray.map((error) => error.message);
      const errodObject = {};
      errodObject.texts = errorTexts;
      errodObject.type = 'error';
      store.commit('setFormMessages', errodObject);
    }
  }

  async function postData(url, payload, params, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
          params: {
            ...params,
          },
        }
        : {
          headers: {
            'Batch-Numbers': store.getters.getBatchNumbers,
          },
          params: {
            ...params,
          },
        };
      store.commit('setFormMessages', null);
      const { data: response } = await axios.post(url, payload, headers);
      return response;
    } catch (error) {
      const erorArray = error.response.data.errors;
      const errorTexts = erorArray.map((error) => error.message);
      const errodObject = {};
      errodObject.texts = errorTexts;
      errodObject.type = 'error';
      store.commit('setFormMessages', errodObject);
    }
  }
  async function putData(url, payload, params, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
          params: {
            ...params,
          },
        }
        : {
          headers: {
            'Batch-Numbers': store.getters.getBatchNumbers,
          },
          params: {
            ...params,
          },
        };
      store.commit('setFormMessages', null);
      const { data: response } = await axios.put(url, payload, headers);
      return response;
    } catch (error) {
      const erorArray = error.response.data.errors;
      const errorTexts = erorArray.map((error) => error.message);
      const errodObject = {};
      errodObject.texts = errorTexts;
      errodObject.type = 'error';
      store.commit('setFormMessages', errodObject);
    }
  }
  async function postRawData(url, payload, params, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
          params: {
            ...params,
          },
        }
        : {
          headers: {
            'Batch-Numbers': store.getters.getBatchNumbers,
          },
          params: {
            ...params,
          },
        };
      store.commit('setFormMessages', null);
      const response = await axios.post(url, payload, headers);
      return response;
    } catch (error) {
      const erorArray = error.response.data.errors;
      const errorTexts = erorArray.map((error) => error.message);
      const errodObject = {};
      errodObject.texts = errorTexts;
      errodObject.type = 'error';
      store.commit('setFormMessages', errodObject);
    }
  }


  async function deleteData(url, queryParams, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store.getters.getToken;
      const headers = token
        ? { headers: { Authorization: `Bearer ${store.getters.getToken}` } }
        : { headers: { 'Batch-Numbers': store.getters.getBatchNumbers } };

      store.commit('setFormMessages', null);
      const { data: response } = await axios.delete(url, {
        ...headers,
        ...queryParams,
      });

      return response;
    } catch (error) {
      const erorArray = error?.response?.data?.errors ?? [];
      const errorTexts = erorArray.map((error) => error.message);
      const errodObject = {};
      errodObject.texts = errorTexts;
      errodObject.type = 'error';
      store.commit('setFormMessages', errodObject);
    }
  }

  return { putData, postData, getData, getRawData, postRawData, deleteData };
}
