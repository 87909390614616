<template>
  <div class="tresiba-detail">
    <div class="container">
      <div class="tresiba-detail__header">
        <div class="text-h4 title">
          Moje léčba inzulinem Tresiba<sup>®</sup>
        </div>
      </div>
      <div class="tresiba-detail__text">
        <Human
          v-if="$vuetify.breakpoint.mdAndUp"
          class="human-illustration"
          fill="#9CB605"
        />
        <p>
          Tresiba<sup>®</sup> je dlouhodobě působící bazální inzulin nazývaný
          inzulin degludek. Používá se k léčbě cukrovky u dospělých,
          dospívajících a dětí ve věku od 1&nbsp;roku.
        </p>
        <p>
          Protože inzulin Tresiba<sup>®</sup> má dlouhodobý účinek na snížení
          hladiny cukru v krvi, užívá se jednou denně a působí po celý den i
          noc. Ideálně by se měl inzulin Tresiba<sup>®</sup> aplikovat vždy ve
          stejnou denní dobu, vyberte si čas, kdy vám to nejvíce vyhovuje.
        </p>
        <p>
          Váš lékař vám poradí, kolik jednotek přípravku Tresiba<sup>®</sup>
          každý den aplikovat a jak upravovat velikost dávky v návaznosti na
          vaše hladiny glukózy v krvi.
        </p>
        <p>
          Přípravek Tresiba<sup>®</sup> lze používat s inzulinovými přípravky s
          rychlým účinkem aplikovanými k jídlu.
        </p>
        <p>
          Chcete-li se dozvědět více o léčbě přípravkem Tresiba<sup>®</sup>, stáhněte si
          edukační materiály níže nebo si je nechte zaslat na vaši adresu.
        </p>
        <Earth
          v-if="$vuetify.breakpoint.mdAndUp"
          class="earth-illustration"
          fill="#9CB605"
        />
        <Hand
          v-if="$vuetify.breakpoint.mdAndUp"
          class="hand-illustration"
          fill="#9CB605"
        />
      </div>

      <Microscope
        v-if="$vuetify.breakpoint.mdAndUp"
        class="microscope-illustration"
        fill="#9CB605"
      />
    </div>
  </div>
</template>

<script>
import Earth from "../illustration/Earth.vue";
import Hand from "../illustration/Hand.vue";
import Human from "../illustration/Human.vue";
import Microscope from "../illustration/Microscope.vue";
export default {
  components: { Microscope, Hand, Earth, Human },
};
</script>

<style lang="scss" scoped>
.tresiba-detail {
  &__header {
    display: inline;
    .title {
      display: inline-block;
      margin-bottom: 1rem;
    }
    position: relative;
  }

  &__text {
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;

    .human-illustration {
      position: absolute;
      right: 22rem;
      top: -9rem;
      max-width: 10rem;
    }
    .earth-illustration {
      position: absolute;
      left: -8.3rem;
      top: 4rem;
    }

    .hand-illustration {
      position: absolute;
      max-width: 10rem;
      right: -7.5rem;
      bottom: -8rem;
    }
  }

  .microscope-illustration {
    max-width: 32rem;
    margin-left: 10rem;
    margin-top: 0.5rem;
  }
}
</style>
