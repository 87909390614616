<template>
  <v-form class="order-form" @submit.prevent="onSubmit" ref="form">
    <v-progress-linear
      v-if="ediFormLoading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div>
      <InputElement
        :rules="[rules.required, rules.email]"
        v-model="state.email"
        label="Email"
      />
      <InputElement
        :rules="[rules.required]"
        v-model="state.firstName"
        label="Jméno"
      />
      <InputElement
        :rules="[rules.required]"
        v-model="state.lastName"
        label="Přijmení"
      />
      <InputElement
        :rules="[rules.required, rules.isNumberBetween0and1000]"
        v-model="state.maxPatientsCount"
        label="Počet pacientů"
      />
      <ButtonElement
        :text="nurseId ? 'Upravit' : 'Pozvat'"
        type="submit"
        :loading="loading"
      ></ButtonElement>
    </div>
  </v-form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import useApiCall from "../../use/apiCall";
import validation from "../../use/validations";
import ButtonElement from "../elements/ButtonElement.vue";

export default {
  components: { InputElement, ButtonElement },
  setup() {
    const { postData, getData, putData } = useApiCall();
    return { postData, getData, putData };
  },

  props: {
    nurseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      ediFormLoading: false,
      rules: validation,
      state: {
        email: "",
        firstName: "",
        lastName: "",
        maxPatientsCount: "",
      },
    };
  },

  watch: {
    nurseId: {
      async handler() {
        if (this.nurseId) {
          this.ediFormLoading = true;
          const { user } = await this.getData(`users/${this.nurseId}`);
          if (user) {
            this.state.email = user.email;
            this.state.firstName = user.firstName;
            this.state.lastName = user.lastName;
            this.state.maxPatientsCount = user.maxPatientsCount;
          }
          this.ediFormLoading = false;
        } else {
          this.$refs.form.reset();
          this.state.email = "";
          this.state.firstName = "";
          this.state.lastName = "";
          this.state.maxPatientsCount = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      if (this.nurseId) {
        await this.editNurse();
      } else {
        await this.inviteNurse();
      }
      this.loading = false;
    },

    async inviteNurse() {
      const response = await this.postData(
        "nurses/invite",
        { nurse: this.state },
        null,
        true
      );

      if (response) {
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Sestra byla úspěšně pozvána."],
        });
        this.$emit("closeDialog");
        this.$emit('fetchUsers')
      }
    },

    async editNurse() {
      const payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        maxPatientsCount: this.state.maxPatientsCount,
      };
      const response = await this.putData(
        `nurses/${this.nurseId}`,
        { nurse: payload },
        null,
        true
      );

      if (response) {
        this.$store.commit("setFormMessages", {
          type: "success",
          texts: ["Sestra byla úspěšně upravena."],
        });
        this.$emit("closeDialog");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-form {
  .button-element {
    width: 100%;
  }
}
</style>
