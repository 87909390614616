<template>
  <div>
    <div class="pa-8 mb-4">
      <v-row>
        <v-col>
          <div class="border-card">
            <div class="title mb-4">Informace o hmotnosti</div>
            <v-row>
              <v-col md="9">Hmotnost na začátku léčby</v-col>
              <v-col class="text-right"
                >{{ healthMetricsStatistics?.initial?.weightKg }} Kg</v-col
              >
            </v-row>
            <v-row class="mt-0">
              <v-col md="9">Hmotnost nyní</v-col>
              <v-col class="text-right"
                >{{ healthMetricsStatistics?.current?.weightKg }} Kg</v-col
              >
            </v-row>

            <v-divider class="my-4"></v-divider>
            <v-row class="d-flex justify-space-between mb-4">
              <v-col md="9">Změna hmotnosti</v-col>
              <v-col class="text-right"
                >{{ healthMetricsStatistics.diff?.weightKg }} Kg</v-col
              >
            </v-row>
          </div>
        </v-col>
        <v-col md="8">
          <div class="white">
            <LineChart :statsData="weightData" />
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-col>
          <div class="border-card">
            <div class="title mb-4">Informace o BMI</div>
            <v-row>
              <v-col md="9">BMI na začátku léčby</v-col>
              <v-col class="text-right">{{
                healthMetricsStatistics.initial?.bmi.toFixed(1)
              }}</v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col md="9">BMI nyní</v-col>
              <v-col class="text-right">{{
                healthMetricsStatistics.current?.bmi.toFixed(1)
              }}</v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row>
              <v-col md="9">Změna BMI</v-col>
              <v-col class="text-right">
                {{ healthMetricsStatistics.diff?.bmi.toFixed(1) }}
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col md="8">
          <div class="white">
            <LineChart :statsData="bmiData" />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/charts/LineChart.vue";
import useApiCall from "@/use/apiCall";
import useFormatDate from "@/use/formatDate";

export default {
  components: {
    LineChart,
  },

  setup() {
    const { getData } = useApiCall();
    const { getCzechDateWithoutTime } = useFormatDate();

    return {
      getData,
      getCzechDateWithoutTime,
    };
  },

  data() {
    return {
      healthMetrics: [],
      healthMetricsStatistics: {},
    };
  },

  props: {
    patient: {
      type: Object,
      required: true,
    },
  },

  computed: {
    weightData() {
      return {
        labels: this.healthMetrics.map((metric) =>
          this.getCzechDateWithoutTime(metric.trackedAtLocal)
        ),
        data: this.healthMetrics.map((metric) => metric.weightKg),
      };
    },
    bmiData() {
      return {
        labels: this.healthMetrics.map((metric) =>
          this.getCzechDateWithoutTime(metric.trackedAtLocal)
        ),
        data: this.healthMetrics.map((metric) => metric.bmi),
      };
    },
  },

  async mounted() {
    await this.fetchHealthMetrics();
    await this.fetchHealthMetricsStatistics();
  },

  methods: {
    async fetchHealthMetricsStatistics() {
      const patientId = Number(this.$route.params.id);
      const params = {
        patientId,
      };

      const statistics = await this.getData(`/health-metrics/statistics`, {
        params,
      });

      this.healthMetricsStatistics = statistics;
    },
    async fetchHealthMetrics() {
      const patientId = Number(this.$route.params.id);
      const params = {
        patientId,
      };

      const { metrics } = await this.getData(`/health-metrics/`, {
        params,
      });

      this.healthMetrics = metrics;
    },
  },
};
</script>
