<template>
  <div class="products page">
    <div class="products__background"></div>
    <v-img
      width="35"
      src="../../assets/img/illustrations/medicines.svg"
      class="medicine-illustration"
    />
    <div class="container">
      <SectionHeader
        title="Moje léčba"
        text="<p>Pro léčbu diabetu užíváte níže uvedené produkty od společnosti Novo Nordisk. Po rozkliknutí sekce vašeho přípravku, zjistíte více informací o vaší léčbě. Informační brožury si můžete bezplatně stáhnout do svého počítače nebo nechat zaslat na vámi zvolenou adresu.</p><p>V případě, že užíváte více léčivých přípravků, do svého účtu je přidáte <a href='/nastaveni-uctu'>zde</a>.</p>"
      />
      <CardGrid v-if="medicines" :items="medicines" class="card-grid" />
    </div>
  </div>
</template>

<script>
import CardGrid from "../../components/CardGrid.vue";
import SectionHeader from "../../components/SectionHeader.vue";
import ProductsContent from "./ProductsContent.json";
import useApicall from "../../use/apiCall";

export default {
  components: { SectionHeader, CardGrid },
  setup() {
    const { getData, postData } = useApicall();
    return {
      getData,
      postData,
    };
  },
  data() {
    return {
      medicines: null,
      productsContent: ProductsContent,
    };
  },
  computed: {
    isLogged() {
      return !!this.$store.getters.getUser;
    },

    storeBatchNumbers() {
      return this.$store.getters.getBatchNumbers;
    },
  },

  methods: {
    async addBatchNumberForLoginUser(batchNumberValue) {
      const response = await this.postData(
        "users/batch-numbers",
        { batchNumbers: batchNumberValue },
        null,
        false
      );

      if (response) {
        this.$store.dispatch("fetchProducts");
        console.log('addBatchNumberForLoginUser', response)
      }
    },
  },
  async mounted() {
    if (this.isLogged && this.storeBatchNumbers) {
      await this.addBatchNumberForLoginUser(this.storeBatchNumbers);
      this.$store.commit("setBatchNumbers", null);
    }

    ({ medicines: this.medicines } = await this.getData("medicines"));

    this.medicines.forEach((medicine) => {
      this.productsContent.find((content) => {
        if (content.name === medicine.displayName) {
          Object.assign(medicine, { ...content });
        }
      });
    });

    const storeMedicines = this.medicines.map((medicine) => {
      return { id: medicine.id, displayName: medicine.displayName };
    });

    this.$store.commit("setMedicines", storeMedicines);
    if (this.$store.getters.getCheckoutItems === 0) {
      this.$store.dispatch("fetchProducts");
    }
  },
};
</script>

<style lang="scss" scoped>
.products {
  position: relative;
  &__background {
    background: var(--v-bgLight-base);
    height: 40rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: -5rem;
  }

  .card-grid {
    margin-bottom: 4rem;
    ::v-deep .card {
      max-width: 23rem;
      margin: auto;
    }
  }

  .medicine-illustration {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -3rem;
  }
}
</style>
