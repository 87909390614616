var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"tresiba-detail__text"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('Human',{staticClass:"human-illustration",attrs:{"fill":"#292c70"}}):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"primary--text text-h6 mb-4"},[_vm._v(" Dávkování přípravku RYBELSUS"),_c('sup',[_vm._v("®")])]),_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/859272775?h=d7070264f8&badge=0&autopause=0&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","title":"RYBELSUS DÁVKOVÁNÍ PŘÍPRAVKU RYBELSUS"}})])]),_c('v-col',[_c('div',{staticClass:"primary--text text-h6 mb-4"},[_vm._v(" Začněte svůj den správně ")]),_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/859272722?h=054756c041&badge=0&autopause=0&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","title":"RYBELSUS_ZAČNĚTE SVUJ DEN SPRÁVNĚ (2)"}})])])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Earth',{staticClass:"earth-illustration",attrs:{"fill":"#292c70"}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Hand',{staticClass:"hand-illustration",attrs:{"fill":"#292c70"}}):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('Microscope',{staticClass:"microscope-illustration",attrs:{"fill":"#292c70"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tresiba-detail__header"},[_c('div',{staticClass:"text-h4 title"},[_vm._v(" Moje léčba přípravkem Rybelsus"),_c('sup',[_vm._v("®")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" RYBELSUS"),_c('sup',[_vm._v("®")]),_vm._v(" je jednou denně užívaná tableta pro dospělé s diabetem 2. typu, která vám v kombinaci se zdravou stravou a pohybem může pomoci dosáhnout vašich cílů. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" RYBELSUS"),_c('sup',[_vm._v("®")]),_vm._v(" je perorální přípravek pro léčbu diabetu 2. typu, který funguje podobně jako přírodní hormon GLP-1, jenž se do těla vyplavuje po jídle. Pomáhá vašemu tělu regulovat hladinu cukru v krvi, pokud je příliš vysoká, a snižovat hmotnost. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"primary--text text-h6 mb-4"},[_vm._v(" Výhody přípravku RYBELSUS"),_c('sup',[_vm._v("®")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"mb-4"},[_c('li',[_vm._v(" snížení hladiny cukru v krvi, které vám umožní dosáhnout vaší cílové hladiny HbA1c ")]),_c('li',[_vm._v("úspěšnější hubnutí.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Přípravek RYBELSUS"),_c('sup',[_vm._v("®")]),_vm._v(" se užívá nalačno, nejlépe hned po probuzení a zapije se douškem vody (max. 120 ml). Předtím než budete jíst, pít či užívat jiné léky je potřeba vyčkat 30 minut. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Chcete-li vědět více o přínosech vaší léčby a správném užívání léčivého přípravku RYBELSUS"),_c('sup',[_vm._v("®")]),_vm._v(", stáhněte si níže uvedené materiály nebo si je nechte poslat domů poštou. ")])
}]

export { render, staticRenderFns }