import { render, staticRenderFns } from "./RecoveryMail.vue?vue&type=template&id=7caf2b09&scoped=true&"
import script from "./RecoveryMail.vue?vue&type=script&lang=js&"
export * from "./RecoveryMail.vue?vue&type=script&lang=js&"
import style0 from "./RecoveryMail.vue?vue&type=style&index=0&id=7caf2b09&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7caf2b09",
  null
  
)

export default component.exports